<template>
  <div>
    <div class="flex flex-col gap-4">
      <div
        v-for="(checks, severity) in checksBySeverity"
        :id="severity"
        :key="severity"
      >
        <bb-base-card
          v-if="!(severity === 'disabled' && isExternal)"
          :class="[checks.status !== 'failed' ? 'cursor-pointer' : '']"
          :checkbox="false"
          hover-disabled
          open-by-default
          full-click-expand
        >
          <template #first>
            <div class="flex flex-row items-center gap-2">
              <component
                :is="severityContent[severity].icon"
                class="p-1 rounded-full"
                :class="severityContent[severity].headerClass"
              />
              <p class="h4">
                <span>{{ checks.length }}</span> {{ severityContent[severity].title }}
              </p>
            </div>
          </template>
          <template #expanded-content>
            <div
              v-if="!checks.length"
              class="flex flex-row justify-center gap-4 p-4"
            >
              <img
                :src="emptyMessages[severity].img"
                alt="empty message image"
              />
              <div class="flex flex-col my-auto">
                <p class="font-medium">{{ emptyMessages[severity].title }}</p>
                <p class="font-normal">{{ emptyMessages[severity].subtitle }}</p>
              </div>
            </div>
            <div
              v-else
              class="flex flex-col rounded-lg divide-y divider-neutral-100 bg-neutral-0"
            >
              <div
                v-for="check in checks"
                :key="check.id"
                class="w-full py-4 flex flex-col md:flex-row justify-between items-center px-4"
                :class="[checks.status === 'failed' ? 'cursor-default' : '']"
                @click.stop="openModal(check)"
              >
                <div
                  v-if="check.status === 'failed'"
                  class="flex flex-row items-center gap-2 text-bb-disabled-buttons"
                >
                  <ic-alert
                    v-if="check.status_reason === 'no_brand_terms'"
                    class="p-1 text-neutral-100"
                  ></ic-alert>
                  <ic-alert-rhombus
                    v-else
                    class="p-1 rounded-full"
                  />
                  <p>{{ check.title }}</p>
                </div>
                <div
                  v-else
                  class="flex flex-row gap-2 items-center"
                >
                  <component
                    :is="severityContent[checkSeverity(check)].icon"
                    class="p-1 rounded-full"
                    :class="severityContent[checkSeverity(check)].iconClass"
                  />
                  <p>{{ check.title }}</p>
                </div>

                <div
                  v-if="check.status === 'failed'"
                  class="flex flex-row mr-7 gap-10"
                >
                  <div
                    v-if="check.status_reason === 'no_brand_terms' && !readonly"
                    class="text-bb-brand-purple flex flex-row gap-2 items-center cursor-pointer"
                    @click.stop="$router.push({ name: 'site:settings:site', query: { tab: 'SiteAndBusinessForm' } })"
                  >
                    <ic-cog :size="16" />
                    <p>Add brand terms to enable</p>
                  </div>
                  <new-tooltip
                    direction="top"
                    class="flex flex-row text-bb-error gap-2 items-center font-medium p1"
                  >
                    <p>Invalid</p>
                    <ic-alert-circle :size="16" />
                    <template #content>An error occurred while running the check.</template>
                  </new-tooltip>
                </div>
                <div
                  v-else-if="check.status === 'skipped'"
                  class="flex flex-row gap-1 items-center"
                  :class="{
                    'grid-cols-4': !readonly && check.status_reason === 'no_brand_terms',
                    'grid-cols-2': !readonly,
                  }"
                >
                  <div
                    v-if="check.status_reason === 'disabled_by_user'"
                    class="flex flex-row items-center gap-x-6"
                  >
                    <p class="p3">Dismissed on : {{ formatDate(check.disabled_at) }}</p>
                    <merge-button-round
                      button-type="secondary"
                      class="h-8"
                      @click="restoreCheck(check)"
                    >
                      <template #left-icon>
                        <ic-reset :size="16" />
                      </template>
                      Restore</merge-button-round
                    >
                  </div>
                  <div
                    v-if="check.status_reason !== 'disabled_by_user'"
                    class="w-32 bg-neutral-50 rounded-2xl items-center"
                  >
                    <p class="text-bb-neutral-gray capitalize p3 py-1 text-center font-bold">
                      {{ check.category == 'ads' ? 'Ads & Assets' : check.category }}
                    </p>
                  </div>
                  <merge-button-round
                    v-if="!readonly"
                    button-type="tertiary"
                    @click="openModal(check)"
                  >
                    <p>See more</p>
                  </merge-button-round>
                </div>
                <div
                  v-else
                  class="grid gap-2 items-center"
                  :class="{
                    'grid-cols-1': readonly && check.issues_count === 0,
                    'grid-cols-2': (readonly && check.issues_count > 0) || (!readonly && check.issues_count === 0),
                    'grid-cols-3': !readonly && check.issues_count > 0,
                  }"
                >
                  <p
                    v-if="check.issues_count > 0"
                    class="h5 font-bold px-2 rounded-full ml-auto text-right mr-4"
                    :class="severityContent[check.severity].headerClass"
                  >
                    {{ check.issues_count }}
                  </p>
                  <div class="w-32 bg-neutral-50 rounded-2xl items-center">
                    <p class="text-bb-neutral-gray capitalize p3 py-1 text-center font-bold">
                      {{ check.category == 'ads' ? 'Ads & Assets' : check.category }}
                    </p>
                  </div>
                  <merge-button-round
                    v-if="!readonly"
                    button-type="tertiary"
                    @click="openModal(check)"
                  >
                    <p>See more</p>
                  </merge-button-round>
                </div>
              </div>
            </div>
          </template>
        </bb-base-card>
      </div>
    </div>
  </div>
</template>

<script>
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcAlertTriangle from 'vue-material-design-icons/AlertOutline'
import IcLightbulbOutline from 'vue-material-design-icons/LightbulbOutline'
import IcCheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import bbBaseCard from '@/components/card/bbBaseCard.vue'
import IcAlertRhombus from 'vue-material-design-icons/AlertRhombusOutline'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import IcMinusCircleOutline from 'vue-material-design-icons/MinusCircleOutline'
import IcCog from 'vue-material-design-icons/Cog'
import IcAlert from 'vue-material-design-icons/Alert'
import IcCircleCancel from '../icon/ic-circle-cancel.vue'
import IcReset from '../icon/ic-reset.vue'
import dayjs from 'dayjs'

export default {
  name: 'AuditCheckList',
  components: {
    IcAlertCircle,
    IcAlertTriangle,
    IcLightbulbOutline,
    IcCheckCircleOutline,
    bbBaseCard,
    IcAlertRhombus,
    NewTooltip,
    IcMinusCircleOutline,
    IcCog,
    IcAlert,
    IcCircleCancel,
    IcReset,
  },
  props: {
    checks: {
      type: Array,
      required: false,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checksBySeverity() {
      const severityOrder = ['urgent', 'warning', 'optimization', 'passed']
      const groupedChecks = { skipped: [], disabled: [] }

      // Group checks by severity
      this.checks.forEach(check => {
        if (check.status_reason === 'disabled_by_user') {
          groupedChecks.disabled.push(check)
        } else if (check.status === 'skipped' || check.status === 'failed') {
          groupedChecks.skipped.push(check)
        } else {
          if (!groupedChecks[check.severity]) {
            groupedChecks[check.severity] = []
          }
          groupedChecks[check.severity].push(check)
        }
      })

      // Ensure every severity in severityOrder exists, even if it's empty
      severityOrder.forEach(severity => {
        if (!groupedChecks[severity]) {
          groupedChecks[severity] = []
        }
      })

      // Separate non-empty and empty severity groups
      const nonEmptySeverities = {}
      const emptySeverities = {}
      severityOrder.forEach(severity => {
        if (groupedChecks[severity].length > 0) {
          nonEmptySeverities[severity] = groupedChecks[severity]
        } else {
          emptySeverities[severity] = groupedChecks[severity]
        }
      })

      // Add 'skipped' and 'disabled' severity at the end
      const orderedSeverities = {
        ...nonEmptySeverities,
        ...emptySeverities,
        skipped: groupedChecks.skipped,
        disabled: groupedChecks.disabled,
      }

      return orderedSeverities
    },
    severityContent() {
      return {
        urgent: {
          icon: 'ic-alert-triangle',
          headerClass: 'text-bb-error bg-bb-red-50',
          iconClass: 'text-bb-error',
          title: 'Urgent Issues',
        },
        warning: {
          icon: 'ic-alert-circle',
          headerClass: 'text-bb-warning bg-bb-yellow-50',
          iconClass: 'text-bb-warning',
          title: 'Warnings',
        },
        optimization: {
          icon: 'ic-lightbulb-outline',
          headerClass: 'text-bb-brand-purple bg-bb-secondary-purple',
          iconClass: 'text-bb-brand-purple',
          title: 'Optimizations',
        },
        passed: {
          icon: 'ic-check-circle-outline',
          headerClass: 'text-success bg-bb-success-bg',
          iconClass: 'text-success',
          backgroundClass: 'bg-bb-success-bg',
          title: 'Passed Checks',
        },
        skipped: {
          icon: 'ic-minus-circle-outline',
          headerClass: 'text-bb-disabled-buttons bg-neutral-50',
          iconClass: 'text-bb-disabled-buttons',
          title: 'Inactive Checks',
        },
        disabled: {
          icon: 'ic-circle-cancel',
          headerClass: 'text-bb-disabled-buttons bg-neutral-50',
          iconClass: 'text-bb-disabled-buttons',
          title: 'Dismissed Checks',
        },
      }
    },
    checkSeverity() {
      return check => {
        if (check.status_reason === 'disabled_by_user') {
          return 'disabled'
        } else if (check.status === 'skipped') {
          return 'skipped'
        } else {
          return check.severity
        }
      }
    },
    emptyMessages() {
      return {
        passed: {
          title: 'You haven’t passed any checks.',
          subtitle: 'Please take action on the suggested recommendations.',
          img: '/content/images/brightbid/robot-red-warning.svg',
        },
        optimization: {
          title: 'All Set!',
          subtitle: 'Your account is optimized.',
          img: '/content/images/brightbid/robot-bulb.svg',
        },
        warning: {
          title: 'Great!',
          subtitle: 'No warnings to display.',
          img: '/content/images/brightbid/robot-warning.svg',
        },
        urgent: {
          title: 'Great!',
          subtitle: 'No urgent issues to display.',
          img: '/content/images/brightbid/robot-urgent.svg',
        },
        skipped: {
          title: 'Great!',
          subtitle: 'No inactive checks to display.',
          img: '/content/images/brightbid/robot-skipped-small.svg',
        },
        disabled: {
          title: 'Dismissed checks.',
          subtitle: 'No dismissed checks.',
          img: '/content/images/brightbid/robot-dismissed.svg',
        },
      }
    },
  },
  methods: {
    openModal(check) {
      if (check.status !== 'failed') {
        this.$emit('openModal', check)
      }
    },
    restoreCheck(check) {
      this.$emit('restoreCheck', check)
    },
    scrollToSeverity(severity) {
      this.$nextTick(() => {
        const element = document.getElementById(severity) // Check for element with ID

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        } else {
          console.error(`Element with ID ${severity} not found.`)
        }
      })
    },
    formatDate(dateString) {
      return dayjs(dateString).format('DD/MM/YYYY')
    },
  },
}
</script>
